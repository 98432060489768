import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../custom-hooks/outsideClick";

import FilterItemUnselected from "./FilterItemUnselected";
import FilterItemSelected from "./FilterItemSelected";
import FilterInput from "./FilterInput";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const UniversityFilter = ({ universities, selectedUniversities, setSelectedUniversitiesAndUpdateFilters, getScholarships }) => {

    // autocomplete to instantly filter the university list based on what the user has typed in the input field
    const [search, setSearch] = useState("");
    const [searchedUniversities, setSearchedUniversities] = useState([]);
    const searchUniversities = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        if (e.target.value.length > 0) {
            setSearchedUniversities(universities.filter(({ name }) => name.toLowerCase().includes(e.target.value.toLowerCase())))
        } else {
            setSearchedUniversities([]);
        }
    }

    const addSelectedUniversity = async (item) => {
        // adds university to the "selected universities" state
        const newSelectedUniversities = [...selectedUniversities, item];

        // removes the selected university from the "searched universities" state
        if (searchedUniversities.length > 0) {
            setSearchedUniversities(searchedUniversities.filter((el) => el.identifier.toLowerCase() !== item.identifier.toLowerCase()));
        }

        // empty the value of the input field
        setSearch("");

        setIsFocus(false);

        setSelectedUniversitiesAndUpdateFilters(newSelectedUniversities);
    }

    const removeSelectedUniversity = (item) => {
        // removes the university from the "selected universities" state
        const newSelectedUniversities = selectedUniversities.filter((el) => el.identifier.toLowerCase() !== item.identifier.toLowerCase());

        // re-adds the unselected university to the searched university list only if the user is searching something in the input and if the name of the canceled university includes the current value in the input
        if (searchedUniversities.length > 0 && item.name.toLowerCase().includes(search.toLowerCase())) {
            setSearchedUniversities([...searchedUniversities, item]);
        }

        setSelectedUniversitiesAndUpdateFilters(newSelectedUniversities);
    }

    // detect if the input field is in focus and changes state accordingly to expand/reduce the dropdown
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => {
        setIsFocus(true)
    }

    const handleClickOutside = () => {
        setIsFocus(false)
    };
    const ref = useOutsideClick(handleClickOutside);

    const isMounted = useRef(false);
    useEffect(() => {
        if ( isMounted.current ) {
            getScholarships();            
        } else {
            isMounted.current = true;
        }        
    }, [selectedUniversities]);


    return (
        <div id="universities" className="flex flex-col w-auto" ref={ref}>
            <FilterInput
                label="Università"
                selectedValues={selectedUniversities}
                type="text"
                placeholder="Cerca università"
                style={componentStylesheet.inputText.style}
                onChangeFunction={searchUniversities}
                onFocusFunction={handleFocus}
                search={search}
            />

            <div className="flex flex-wrap gap-1 mt-1">
                {selectedUniversities?.map((filter, index) => {
                    return (
                        <FilterItemSelected
                            key={filter.identifier}
                            index={index + 1}
                            name={filter.name}
                            onClickFunction={() => removeSelectedUniversity(filter)}
                        />
                    )
                })
                }
            </div>

            <div className={`max-h-60 mt-1 drop-shadow-2xl rounded-xl overflow-y-auto ${isFocus ? "block" : "hidden"}`}>
                {searchedUniversities.length > 0 ?
                    searchedUniversities.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => { addSelectedUniversity(item) }}
                                index={index + 1}
                                name={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                            />
                        )
                    })
                    :
                    universities?.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => { addSelectedUniversity(item) }}
                                index={index + 1}
                                name={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default UniversityFilter;