export const scholarshipTypes = [
    {
        identifier: 1,
        name: "Merito"
    },{
        identifier: 2,
        name: "Reddito"
    },{
        identifier: 3,
        name: "Merito & Reddito"
    },{
        identifier: 4,
        name: "Invalidità"
    },{
        identifier: 5,
        name: "Rifugiati & Cittadini Stranieri"
    },{
        identifier: 6,
        name: "Sport"
    },{
        identifier: 7,
        name: "Tesi"
    },{
        identifier: 8,
        name: "Altro"
    }
]