import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../index.css';

import { ContextIndemnity } from "../contexts/ContextIndemnity";

import { italianRegions } from "../data/regions";

import Container from "../components/Container";
import UniversityFilter from "../components/UniversityFilter";
import FacultyFilter from "../components/FacultyFilter";
import RegionFilter from "../components/RegionFilter";
import TypeFilter from "../components/TypeFilter";
import DegreeFilter from "../components/DegreeFilter";
import ScholarshipCard from "../components/ScholarshipCard";
import HashTag from "../components/HashTag";
import HashTagSimple from "../components/HashTagSimple";
import Error429 from "../components/Error429";
import PopUpModal from "../components/PopUpModal";

import { componentStylesheet } from "../component-styles/componentStylesheet";

import IconFilterWhite from "../assets/icons/icon-filter-white.svg";
import IconCloseWhite from "../assets/icons/icon-close-white.svg";
import IconArrowDownPurple from "../assets/icons/icon-arrow-down-purple.svg";
import IconFeedbackPurple from "../assets/icons/icon-feedback-purple.svg";
import IconEmailPurple from "../assets/icons/icon-email-purple.svg";
import IconOpenNew from "../assets/icons/icon-open-new.svg";
import IllustrationSearch from "../assets/illustrations/search-illustration.svg"
import IllustrationEmpty from "../assets/illustrations/empty-illustration.svg";
import Logo from "../assets/logo/logo.png";
import LogoIcon from "../assets/logo/logo-icon.png";

const headers = new Headers({
    'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
    'Content-Type': 'application/json'
});

const Search = ({ selectedUniversities, setSelectedUniversities, selectedFaculties, setSelectedFaculties, selectedRegions, setSelectedRegions, selectedTypes, setSelectedTypes, selectedDegrees, setSelectedDegrees, results, setResults }) => {

    const [expand, setExpand] = useState(true);
    const [hasSearched, setHasSearched] = useState(false)
    const [numberOfResults, setNumberOfResults] = useState();
    const [nextPage, setNextPage] = useState(null);
    const [regions, setRegions] = useState(italianRegions);
    const [tooManyRequests, setTooManyRequests] = useState(false);

    const { handleIndemnity, hasAcceptedIndemnity } = useContext(ContextIndemnity)

    const navigate = useNavigate();

    const getScholarships = async () => {

        // Proper search param (with URL escape chars)
        let searchParams = {
            university__in: selectedUniversities.map(({ identifier }) => `${identifier}`).join(','),
            include_faculty_types: selectedFaculties.map(({ identifier }) => `${identifier}`).join(','),
            university_has_regions: selectedRegions.map(({ name }) => `${name}`).join(','),
            scholarship_type__in: selectedTypes.map(({ name }) => `${name}`).join(',')
        };
        if (selectedDegrees.length > 0) {
            const selectedDegreeNames = selectedDegrees.map(({ name }) => `${name}`);
            if (selectedDegreeNames.includes('Triennale')) {
                searchParams['undergraduate'] = true;
            }
            if (selectedDegreeNames.includes('Magistrale')) {
                searchParams['graduate'] = true;
            }
            if (selectedDegreeNames.includes('Magistrale a Ciclo Unico')) {
                searchParams['combined'] = true;
            }            
        }

        const searchParamsString = new URLSearchParams(searchParams).toString();

        if (hasAcceptedIndemnity) {
            try {
                const api = await fetch(
                    'https://vision-app-ec8f3df96d12.herokuapp.com/scholarship/scholarship/?' + searchParamsString, {
                    method: 'GET',
                    headers: headers
                });

                if (api.status === 200) {
                    const data = await api.json();
                    setNumberOfResults(data.count);
                    setNextPage(data.next);
                    setResults(data.results);
                    if ( !hasSearched ) {
                        setHasSearched(true);
                    }                
                } else if ( api.status === 429 ) {
                    setTooManyRequests(true);
                    setTimeout(() => {
                        setTooManyRequests(false);
                        window.location.reload(true);
                    }, 60000)
                }
            } catch (error) {
                alert("Errore inaspettato :-( Riprova tra 1 minuto!")
            }
        }
    }

    // api call to retrieve the university list
    const [allUniversities, setAllUniversities] = useState([]);
    const [universities, setUniversities] = useState([]);
    const getUniversities = async () => {
        try {
            const api = await fetch('https://vision-app-ec8f3df96d12.herokuapp.com/scholarship/university', {
                method: 'GET',
                headers: headers
            });
            const data = await api.json();
            setAllUniversities(data);  // TODO: Review this
            setUniversities(data);
        } catch (error) {
            alert("Errore inaspettato :-( Riprova tra 1 minuto!")
        }        
    }

    // api call to retrieve the faculty list
    const [allFaculties, setAllFaculties] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const getFaculties = async () => {
        try {
                const api = await fetch('https://vision-app-ec8f3df96d12.herokuapp.com/scholarship/facultytype', {
                method: 'GET',
                headers: headers
            });
            const data = await api.json();
            setAllFaculties(data);  // TODO: Review this...
            setFaculties(data);
        } catch (error) {
            alert("Errore inaspettato :-( Riprova tra 1 minuto!")
        }        
    };

    // api call to call the scholarships of the next page
    const nextScholarshipPage = async () => {
        try {
            const api = await fetch(
                `${nextPage}`, {
                method: 'GET',
                headers: headers
            });

            if ( api.status === 200 ) {
                const data = await api.json();
                setNextPage(data.next);
                setResults((prev) => [...prev, ...data.results])
            } else if ( api.status === 429 ) {
                setTooManyRequests(true);
                setTimeout(() => {
                    setTooManyRequests(false);
                    window.location.reload(true);
                }, 60000)
            }            
        } catch (error) {
            alert("Errore inaspettato :-( Riprova tra 1 minuto!")
        }        
    }

    useEffect(() => {
        getScholarships();
    }, [hasAcceptedIndemnity]);

    useEffect(() => {
        getUniversities();
        getFaculties();
    }, []);

    const notEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

    const uniInRegions = (newSelectedRegions, uni) => {
        const nameInUniRegions = ({ name }) => uni.regions.includes(name);
        if (notEmptyArray(newSelectedRegions)) {
            return newSelectedRegions.filter(nameInUniRegions).length > 0;
        } else {
            return true;
        }
    };

    const facultiesInUni = (newSelectedFaculties, uni) => {
        const IDInUniFaculties = ({ identifier }) => uni.faculty_type_ids.includes(identifier);
        if (notEmptyArray(newSelectedFaculties)) {
            return newSelectedFaculties.filter(IDInUniFaculties).length > 0;
        } else {
            return true;
        }
    };

    const setSelectedRegionsAndUpdateFilters = (newSelectedRegions) => {
        let availableUniversities = [];
        let availableFacultyTypeIDs = new Set();
        allUniversities.forEach((uni) => {
            if (uniInRegions(newSelectedRegions, uni) && facultiesInUni(selectedFaculties, uni)) {
                if (!selectedUniversities.includes(uni)) {
                    availableUniversities.push(uni);
                }
                availableFacultyTypeIDs = new Set([...availableFacultyTypeIDs, ...uni.faculty_type_ids]);
            };
        });

        const availableRegions = italianRegions.filter((reg) => !newSelectedRegions.includes(reg));

        const availableFaculties = allFaculties.filter(({ identifier }) => {
            return availableFacultyTypeIDs.has(identifier) && !selectedFaculties.includes(identifier);
        });

        setRegions(availableRegions);
        setFaculties(availableFaculties);
        setUniversities(availableUniversities);
        setSelectedRegions(newSelectedRegions);
    };

    const setSelectedFacultiesAndUpdateFilters = (newSelectedFaculties) => {
        let availableUniversities = [];
        let availableFacultyTypeIDs = new Set();
        allUniversities.forEach((uni) => {
            if (uniInRegions(selectedRegions, uni)) {
                if (facultiesInUni(newSelectedFaculties, uni) && !selectedUniversities.includes(uni)) {
                    availableUniversities.push(uni);
                }
                availableFacultyTypeIDs = new Set([...availableFacultyTypeIDs, ...uni.faculty_type_ids]);
            };
        });

        const availableFaculties = allFaculties.filter((fac) => {
            return availableFacultyTypeIDs.has(fac.identifier) && !newSelectedFaculties.includes(fac);
        });
        
        setFaculties(availableFaculties);
        setUniversities(availableUniversities);
        setSelectedFaculties(newSelectedFaculties);
    };

    const setSelectedUniversitiesAndUpdateFilters = (newSelectedUniversities) => {
        const availableUniversities = allUniversities.filter((uni) => {
            return uniInRegions(selectedRegions, uni) && facultiesInUni(selectedFaculties, uni) && !newSelectedUniversities.includes(uni);
        });
        setUniversities(availableUniversities)
        setSelectedUniversities(newSelectedUniversities);
    };

    return (
        <>
        {!tooManyRequests && <>

            {!hasAcceptedIndemnity &&
                <PopUpModal handleIndemnity={handleIndemnity} />
            }            

            <nav className="z-30 flex items-center sticky top-0 w-full h-[8vh] bg-[#FFFFFF]">
                <Container more={"flex justify-between items-center"}>
                    <div className="flex items-center">
                        <img className="h-[40px] md:h-[48px] aspect-auto" src={LogoIcon} alt="Vision" />
                        <h1 className="font-nunito text-lg font-bold text-[#fbc719]">Italo Scholarship Platform</h1>
                    </div>
                    {/* <img className="h-[36px] aspect-auto" src={Logo} alt="Vision logo" /> */}
                    <div className={`relative w-8 h-8 bg-[#6D59EF] rounded-full flex items-center justify-center overflow-clip`}>
                        <div className={`absolute flex flex-col w-8 ${expand ? "animate-[moveUp_.2s_ease-in-out_forwards]" : "animate-[moveDown_.2s_ease-in-out_forwards]"}`}>
                            <img src={IconCloseWhite} alt="close" onClick={() => setExpand(false)} />
                            <img src={IconFilterWhite} alt="filter" onClick={() => setExpand(true)} />
                        </div>
                    </div>
                </Container>
            </nav>

            <div className={`z-20 bg-[#FFFFFF] w-full fixed top-0 overflow-y-scroll ${expand ? "animate-[compressFiltersXs_.5s_ease-in-out_forwards]" : "animate-[expandFiltersXs_.5s_ease-in-out_forwards]"}`}>
                <Container more={""}>
                    <div>
                        <h1 className="text-[1.4em] md:text-3xl font-bold">Cerca la tua borsa di studio</h1>
                        {/* <p className="mt-1 font-lato">Cerca la tua borsa di studio ora tramite Italo Scholarship Platform.</p> */}
                    </div>

                    <div className="flex flex-col gap-4 mt-3">

                        <TypeFilter
                            selectedTypes={selectedTypes}
                            setSelectedTypes={setSelectedTypes}
                            getScholarships={getScholarships}
                        />

                        <RegionFilter
                            regions={regions}
                            selectedRegions={selectedRegions}
                            setSelectedRegionsAndUpdateFilters={setSelectedRegionsAndUpdateFilters}
                            getScholarships={getScholarships}
                        />

                        <FacultyFilter
                            faculties={faculties}
                            selectedFaculties={selectedFaculties}
                            setSelectedFacultiesAndUpdateFilters={setSelectedFacultiesAndUpdateFilters}
                            getScholarships={getScholarships}
                        />

                        <UniversityFilter
                            universities={universities}
                            selectedUniversities={selectedUniversities}
                            setSelectedUniversitiesAndUpdateFilters={setSelectedUniversitiesAndUpdateFilters}
                            getScholarships={getScholarships}
                        />

                        <DegreeFilter
                            selectedDegrees={selectedDegrees}
                            setSelectedDegrees={setSelectedDegrees}
                            getScholarships={getScholarships}
                        />

                        {hasAcceptedIndemnity && numberOfResults > 0 ?
                            <button
                                onClick={() => setExpand(false)}
                                className={componentStylesheet.buttonPrimaryEnabled.style}
                            >
                                Guarda {numberOfResults} borse
                            </button>
                        : hasAcceptedIndemnity && numberOfResults === 0 ?
                            <button
                                className={componentStylesheet.buttonPrimaryDisabled.style}
                                disabled={true}
                            >
                                Nessuna borsa per questa combinazione
                            </button>
                        :
                            <button
                                className={componentStylesheet.buttonPrimaryDisabled.style}
                                disabled={true}
                            >
                                Accetta le condizioni per cercare
                            </button>
                        }
                    </div>
                </Container>
            </div>
            
            <Container more={"relative z-10"}>

                <div className="flex flex-col gap-2 fixed bottom-14 right-[4.166%] translate-x-[-4.166%] md:right-[20%] md:translate-x-[-20%] lg:right-[28.166%] lg:translate-x-[-28.166%] xl:right-[32.6%] xl:translate-x-[-32.6%]">
                    <div className="flex items-center justify-center w-11 h-11 rounded-full bg-white drop-shadow-3xl border-[#F4F5F7]" onClick={() => navigate('/subscribe-to-newsletter')}>                        
                        <img className="w-8 h-8" src={IconEmailPurple} alt="" />
                    </div>
                    <div className="flex items-center justify-center w-11 h-11 rounded-full bg-white drop-shadow-3xl border-[#F4F5F7]" onClick={() => navigate('/give-us-feedback')}>
                        <img className="w-8 h-8" src={IconFeedbackPurple} alt="" />
                    </div>
                </div>

                <div className="fixed bottom-0 left-0 h-[6vh] w-full bg-white border-[#F4F5F7] border-[1px] border-top">
                    <Container more={"flex justify-between items-center h-full"}>
                        <Link to="/terms-and-conditions" className="font-lato text-xs underline text-gray-600">Condizioni Generali d’Utilizzo</Link>
                        <div className="flex items-center gap-1">
                            <Link to="https://visionaps.org/" target="blank" className="font-lato text-xs text-gray-600">To visionaps.org</Link>
                            <img className="w-4" src={IconOpenNew} alt="" />
                        </div>
                    </Container>
                </div>

                {hasSearched && results &&
                    <div className="pb-[8vh] mt-2">
                        <h2 className="font-semibold text-xl mb-1">La tua ricerca</h2>
                        {numberOfResults !== 0 &&
                            <p className="font-regular font-lato text-sm mb-2 text-gray-500">{numberOfResults} borse di studio trovate</p>
                        }

                        {selectedUniversities &&
                            selectedUniversities?.map((item) => {
                                return (
                                    <HashTag
                                        key={item.identifier}
                                        content={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                                    />
                                )
                        })}
                        {selectedFaculties &&
                            selectedFaculties?.map((item) => {
                                return (
                                    <HashTag
                                        key={item.identifier}
                                        content={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                                    />
                                )
                        })}
                        {selectedRegions &&
                            selectedRegions?.map((item) => {
                                return (
                                    <HashTagSimple
                                        key={item.identifier}
                                        content={item.name}
                                    />
                                )
                        })}
                        {selectedTypes &&
                            selectedTypes?.map((item) => {
                                return (
                                    <HashTagSimple
                                        key={item.identifier}
                                        content={item.name}
                                    />
                                )
                        })}
                        {selectedDegrees &&
                            selectedDegrees?.map((item) => {
                                return (
                                    <HashTagSimple
                                        key={item.identifier}
                                        content={item.name}
                                    />
                                )
                        })}

                        {allUniversities.length > 0 && results?.map((scholarship, index) => {
                            const university = allUniversities.filter(({ identifier }) => scholarship.university === identifier)[0];

                            // this is the function to render the faculties of each scholarship, uncomment in case it's requested                                
                            // // for each sholarship, saves the ids of the faculties in an array
                            // let facultyIDs = scholarship.faculty_types;
                            // let facultyNames = [];
                            // // loops through the faculty id list
                            // facultyIDs.forEach(fac => {
                            // // for each faculty id in the array, check when the id is equal to the one of the faculty, and when true it retrieves the faculty name 
                            //     allFaculties.forEach(item => {
                            //         if ( item.identifier === fac ) {
                            //             return facultyNames.push(item.name);
                            //         }
                            //     })
                            // })

                            return (
                                <ScholarshipCard
                                    key={scholarship.identifier}
                                    name={scholarship.name}
                                    scholarship_type={scholarship.scholarship_type}
                                    pdf_link={scholarship.pdf_link}
                                    uniName={university.name}
                                    uniRegions={university.regions}
                                    graduate={scholarship.graduate}
                                    undergraduate={scholarship.undergraduate}
                                    combined={scholarship.combined}
                                    website_link={scholarship.website_url}
                                    index={index}
                                    //facultyNames={facultyNames}
                                />
                            )
                        })
                        }
                        {nextPage != null &&
                            <div className="flex items-center justify-center">
                                <button onClick={nextScholarshipPage} className="flex items-center justify-center py-2 w-full bg-[#EBE8FB] text-[#6D59EF] font-semibold rounded-lg mt-2">
                                    <img className="w-6" src={IconArrowDownPurple} alt="" />
                                    <span>Carica altre borse</span>
                                </button>
                            </div>
                        }
                        {nextPage == null && results?.length > 0 &&
                            <div className="flex items-center justify-center mt-4">
                                <p className="font-semibold">🎉 You've seen them all!</p>
                            </div>
                        }
                    </div>
                }

                {hasSearched && results?.length === 0 &&
                    <div className="pt-10 flex flex-col items-center justify-center">
                        <img src={IllustrationEmpty} alt="" />
                        <p className="text-center text-lg w-10/12 text-gray-700 font-lato">Nessuna borsa di studio corrispondete alla tua ricerca!</p>
                    </div>
                }
                
                {!hasSearched &&
                    <div className="pt-4 flex flex-col items-center justify-center">
                        <img src={IllustrationSearch} alt="" />
                        <p className="text-center text-lg w-10/12 text-gray-700 font-lato">Imposta i filtri ed effettua la ricerca per visualizzare i risultati!</p>
                    </div>
                }
            </Container>            
        </>}
        

        {tooManyRequests &&
            <Error429 tooManyRequests={tooManyRequests} setTooManyRequests={setTooManyRequests} />
        }            
        </>
    )
}

export default Search;