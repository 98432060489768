import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../custom-hooks/outsideClick";
import { scholarshipTypes } from "../data/scholarshipTypes";

import FilterItemUnselected from "./FilterItemUnselected";
import FilterItemSelected from "./FilterItemSelected";
import FilterInput from "./FilterInput";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const TypeFilter = ({ selectedTypes, setSelectedTypes, getScholarships }) => {
    
    // set scholarship types from local js array
    const [types, setTypes] = useState(scholarshipTypes);

    // autocomplete to instantly filter the type list based on what the user has typed in the input field
    const [search, setSearch] = useState("");
    const [searchedTypes, setSearchedTypes] = useState([]);
    const searchTypes = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        if ( e.target.value.length > 0 ) {
            setSearchedTypes(types.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())))            
        } else {
            setSearchedTypes([]);
        }
    }

    const addSelectedType = (item) => {
        // adds type to the "selected types" state
        setSelectedTypes([
            ...selectedTypes,
            {
                ...item
            }
        ]);

        // removes the selected type from the "types" state
        setTypes(types.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        
        // removes the selected type from the "searched types" state
        if ( searchedTypes.length > 0 ) {
            setSearchedTypes(searchedTypes.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        }

        // empty the value of the input field
        setSearch("");

        setIsFocus(false);
    }

    const removeSelectedType = (item) => {
        // removes the type from the "selected types" state
        setSelectedTypes(selectedTypes.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()));

        // re-adds the unselected type to the type list
        setTypes([
            ...types,
            {
                ...item
            }
        ])

        // re-adds the unselected type to the searched type list only if the user is searching something in the input and if the name of the canceled type includes the current value in the input
        if ( searchedTypes.length > 0 ) {
            if ( item.name.toLowerCase().includes(search.toLowerCase()) )
            setSearchedTypes([
                ...searchedTypes,
                {
                    ...item
                }
            ])
        }
    }

    // detect if the input field is in focus and changes state accordingly to expand/reduce the dropdown
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => {
        setIsFocus(true)
    }
    
    const handleClickOutside = () => {
        setIsFocus(false)
    };
    const ref = useOutsideClick(handleClickOutside);

    const isMounted = useRef(false);
    useEffect(() => {
        if ( isMounted.current ) {
            getScholarships();            
        } else {
            isMounted.current = true;
        }        
    }, [selectedTypes]);

    return (
        <div id="types" className="flex flex-col w-auto" ref={ref}>
            <FilterInput
                label="Tipologia"
                selectedValues={selectedTypes}
                type="text"
                placeholder="Cerca tipologia di borsa"
                style={componentStylesheet.inputText.style}
                onChangeFunction={searchTypes}
                onFocusFunction={handleFocus}
                search={search}
            />

            <div className="flex flex-wrap gap-1 mt-1">
                {selectedTypes?.map((filter, index) => {
                    return(
                            <FilterItemSelected
                                key={filter.identifier}
                                index={index + 1}
                                name={filter.name}
                                onClickFunction={() => removeSelectedType(filter)}
                            />
                        )
                    })
                }                
            </div>

            <div className={`max-h-60 mt-1 drop-shadow-2xl rounded-xl overflow-y-auto ${isFocus ? "block" : "hidden"}`}>
                {searchedTypes.length > 0 ?
                    searchedTypes.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedType(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                :
                    types?.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedType(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TypeFilter;