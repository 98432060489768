import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ContextIndemnity } from "../contexts/ContextIndemnity";

import { componentStylesheet } from "../component-styles/componentStylesheet";

import Container from "./Container";

const PopUpModal = ({ handleIndemnity }) => {

    const { handleConsent, acceptedAll, consent } = useContext(ContextIndemnity)    

    return (
        <div className="z-40 w-full h-full bg-[#00000066] fixed flex items-center justify-center">
            <Container more={"bg-[#FFFFFF] z-50 rounded-lg p-6"}>
                <p className="mb-4 font-lato">Benvenuto in Italo Scholarship Platform, un’iniziativa di Vision APS. Per proseguire, ti preghiamo di confermarci di:</p>
                <ul className="flex flex-col gap-2 mb-4">
                    <li className="flex gap-1 items-start">
                        <input type="checkbox" className="mt-1 self-start min-w-[16px] min-h-[16px]" onChange={handleConsent} checked={consent['adult'].accepted} name="adult" />
                        <p className="font-lato text-sm">aver compiuto almeno 16 anni di età</p>
                    </li>
                    <li className="flex gap-1 items-start">
                        <input type="checkbox" className="mt-1 self-start min-w-[16px] min-h-[16px]" onChange={handleConsent} checked={consent['terms-and-conditions'].accepted} name="terms-and-conditions" />
                        <p className="font-lato text-sm">accettare tutte le <Link to="/terms-and-conditions" className="underline">Condizioni Generali d’Utilizzo</Link></p>
                    </li>
                    <li className="flex gap-1 items-start">
                        <input type="checkbox" className="mt-1 self-start min-w-[16px] min-h-[16px]" onChange={handleConsent} checked={consent['articles'].accepted} name="articles" />
                        <p className="font-lato text-sm">accettare <strong>specificatamente</strong>, ai sensi e per gli effetti dell’art. 1341 e 1342 cod. civ., i seguenti <strong>articoli</strong> delle <Link to="/terms-and-conditions" className="underline">Condizioni Generali d’Utilizzo</Link>: art. 1.5, art. 2.1, art. 3 (Assenza di Garanzia); art. 4 (Indennizzo); e art. 6 (Legge Applicabile)</p>
                    </li>
                </ul>
                <button className={acceptedAll ? componentStylesheet.buttonPrimaryEnabled.style : componentStylesheet.buttonPrimaryDisabled.style} disabled={acceptedAll ? false : true} onClick={handleIndemnity}>Accetta ed entra</button>
            </Container>
        </div>
    )
}

export default PopUpModal;