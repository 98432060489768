import React from "react";
import { useNavigate, Link } from "react-router-dom";

import Container from "../components/Container";

import IconArrowLeftWhite from "../assets/icons/icon-arrow-left-white.svg";

const TermsAndConditions = () => {

    const navigate = useNavigate();

    return (
        <>
            <nav className={`z-30 flex items-center sticky top-0 w-full h-[8vh] bg-[#FFFFFF]`}>
                <Container more={"flex justify-start"}>
                <div className={`relative w-8 h-8 bg-[#6D59EF] rounded-full flex items-center justify-center overflow-clip`}>
                        <div className={`absolute flex flex-col w-8 `}>
                            <img src={IconArrowLeftWhite} alt="close" onClick={() => navigate(-1)} />
                        </div>
                    </div>
                </Container>
            </nav>
            <Container more={"font-lato pt-4 pb-4"}>
                <h1 className="text-xl font-bold">Condizioni generali di utilizzo</h1>
                <section className="mt-4">
                    <h3 className="text-lg font-bold">1. Previsioni introduttive</h3>
                    <ol>
                        <li className="mb-2"><span className="pr-2">1.1</span> Le presenti condizioni generali (“<strong>Condizioni Generali</strong>”) regolano i termini e condizioni di utilizzo del sito web <Link to="/" className="underline">https://italo.visionassociation.it/</Link>  (di seguito anche il "<strong>Sito</strong>" o la "<strong>Piattaforma</strong>").</li>
                        <li className="mb-2"><span className="pr-2">1.2</span> Il Sito è gestito da, <strong>Vision APS</strong> iscritta al Registro Unico del Terzo Settore al n. 1208 del 14/02/2022 Fasc. 8.5/2022/52, con C.F. 97907210153, P.IVA 12507390966 e sede in Viale Umbria n. 76, 20135, Milano (di seguito anche l'“<strong>Associazione</strong>").</li>
                        <li className="mb-2"><span className="pr-2">1.3</span>Il Sito ha finalità esclusivamente informative. Effettuando l'accesso o utilizzando i servizi presenti sul Sito, il soggetto (sia persona fisica che rappresentante di persona giuridica) che utilizzi la Piattaforma (“<strong>Utente</strong>”) accetta le presenti condizioni generali (“<strong>Condizioni</strong>”) e si impegna a rispettarle. Se l'Utente non intende accettare le presenti Condizioni, è pregato di astenersi dall'utilizzo del Sito.</li>
                        <li className="mb-2"><span className="pr-2">1.4</span>Si prega di leggere attentamente le presenti Condizioni - così come le altre sezioni del Sito quali <strong>l'informativa sul trattamento dei dati personali e l'informativa sull'utilizzo dei cookie</strong> - prima di utilizzare il Sito ed eventualmente di stamparne una copia per futuro riferimento.</li>
                        <li className="mb-2"><span className="pr-2">1.5</span>L'Associazione si riserva il diritto di modificare le presenti Condizioni e/o la Piattaforma in qualsiasi momento e per qualsiasi motivo, così come di interrompere o sospendere il funzionamento del Sito in qualsiasi momento o di disattivarlo in via definitiva. Tali modifiche avranno effetto dalla data di loro pubblicazione sul Sito. Pertanto, l'Utente è invitato a consultare ad ogni accesso al Sito le presenti Condizioni anche al fine di verificare la presenza di eventuali aggiornamenti o modifiche.</li>
                        <li className="mb-2"><span className="pr-2">1.6</span>L'accesso alla Piattaforma è reso disponibile agli utenti gratuitamente (fermo restando che è a carico dell'Utente il costo del collegamento alla rete Internet utilizzato per accedere al Sito, secondo le tariffe, i termini e le condizioni praticate dal proprio operatore). Ai fini di evitare truffe, l'Utente si impegna a comunicare con l'Associazione solo tramite i suoi canali ufficiali e a non accettare da terzi alcuna somma di denaro o altre utilità per l'utilizzo della Piattaforma.</li>
                        <li className="mb-2"><span className="pr-2">1.7</span>L'Utente non potrà utilizzare il proprio <span className="italic">account</span> e il Sito:
                            <ol>
                                <li className="pl-4"><span className="pr-2">1.7.1</span> in modo tale da causare, o da poter causare, interruzioni, danni o malfunzionamenti agli stessi e alle loro funzionalità; e</li>
                                <li className="pl-4"><span className="pr-2">1.7.2</span> per finalità fraudolente, o comunque per commettere attività illecite.</li>
                            </ol>
                        </li>
                        <li className="mb-2"><span className="pr-2">1.8</span>In ogni caso l'Utente ha l'obbligo di non interferire con il funzionamento del Sito e, in particolare, si impegna a non tentare di eluderne la sicurezza, a non manometterlo e comunque a non pregiudicare in alcun modo l'operatività del Sito o di qualunque sistema informatico, <span className="italic">server, router</span> e qualsiasi altro dispositivo informatico. Nei più ampi termini consentiti dalla legge, l'Associazione non assume inoltre alcuna responsabilità per le attività poste in essere dagli Utenti sulla (o attraverso la) Piattaforma.</li>
                        <li className="mb-2"><span className="pr-2">1.9</span>Qualora una delle clausole delle presenti Condizioni dovesse essere dichiarata, dalla competente autorità, nulla o inefficace, le Condizioni continueranno ad avere piena efficacia per la parte non inficiata da detta nullità o inefficacia.</li>
                        <li className="mb-2"><span className="pr-2">1.10</span>La circostanza che l'Associazione o l'Utente disgiuntamente non facciano in qualsiasi momento valere i diritti a loro riconosciuti da una o più condizioni delle presenti Condizioni non potrà essere intesa come rinuncia a tali diritti né impedirà di pretendere successivamente l'osservanza di ogni e qualsiasi clausola delle presenti Condizioni.</li>
                    </ol>
                </section>

                <section className="mt-4">
                    <h3 className="text-lg font-bold">2. La Piattaforma. Obblighi degli Utenti</h3>
                    <ol>
                        <li className="mb-2"><span className="pr-2">2.1</span> Il Sito funziona da raccoglitore e comparatore delle borse di studio cui si può accedere presso gli istituti di istruzione superiore o universitaria e consente di ottenere un più facile accesso ai siti e ai bandi delle università e degli istituti superiori per reperire le informazioni propedeutiche a presentare domanda di borsa di studio presso questi ultimi. I siti e i bandi universitari appartengono a terze parti, che provvederanno in maniera autonoma tramite il proprio canale ufficiale a fornire ogni idonea informativa e documentazione circa la borsa di studio e il processo di selezione. Gli enti erogatori saranno gli unici soggetti responsabili del corretto trattamento dei dati degli Utenti ai fini delle richieste di borse di studio e del processo di selezione.</li>
                        <li className="mb-2"><span className="pr-2">2.2</span>Ferme restando le peculiarità delle diverse borse di studio e dei siti dei relativi enti, il servizio fornito dalla Piattaforma consiste esclusivamente nella consultazione gratuita delle borse di studio pubbliche erogate dalle 90 università italiane di cui Vision APS ha mappato le borse di studio disponibili sul proprio sito, ovvero sui siti regionali qualora di competenza. Tali borse di studio sono filtrabili per: tipologia, regione, facoltà, università e grado di studio (il “<strong>Servizio</strong>”).</li>
                        <li className="mb-2"><span className="pr-2">2.3</span>L'Associazione è unica ed esclusiva titolare del Sito e di tutti i relativi diritti di proprietà intellettuale e industriale e di ogni suo componente funzionale sviluppata dalla o per conto della stessa, salvi i diritti di terzi concessi in licenza all'Associazione e la proprietà della documentazione e informazioni relative alle singole borse di studio. L'Utente è autorizzato a scaricare, visualizzare o stampare contenuti del Sito per scopi esclusivamente personali e di natura non commerciale, con modalità tali da non arrecare alcun pregiudizio ai diritti di proprietà industriale ed intellettuale dell'Associazione o di eventuali terzi. I contenuti del Sito non potranno in nessun caso essere utilizzati per scopi diversi, ivi compresi, a titolo esemplificativo e non esaustivo, la loro distribuzione, modifica, riproduzione, trasmissione o diffusione. Se l'Utente utilizza il Sito e/o in violazione delle presenti Condizioni, l'Utente potrà essere invitato dalla Associazione a interrompere l'utilizzo del Sito, ferma restando la facoltà dell'Associazione di assumere ogni ulteriore iniziativa per la tutela dei diritti lesi e il risarcimento dei danni subiti.</li>
                        <li className="mb-2"><span className="pr-2">2.4</span>Nessuna titolarità o licenza sul Sito o sui suoi componenti è concessa all'Utente, il quale ha solo la disponibilità in uso del Sito (esclusivamente per finalità personale e non commerciale) limitatamente al periodo e alle modalità di cui alle presenti Condizioni Generali. Vision APS precisa che l'Utente dispone di un diritto di utilizzo non esclusivo per il proprio uso privato del servizio, non cedibile e non utilizzabile per svolgere attività ed erogare servizi verso terzi. L'Utente non è autorizzato a cedere o concedere, a titolo gratuito o a pagamento, in qualsiasi modo, i diritti di utilizzo del servizio a terzi estranei a Vision APS. L'Utente non è neppure autorizzato a concedere, a titolo gratuito o a pagamento, una sub-licenza d'utilizzo.</li>
                        <li className="mb-2"><span className="pr-2">2.5</span>L'Utente si impegna a non riprodurre, duplicare, copiare, vendere, eseguire <span className="italic">framing</span>, rivendere o comunque utilizzare a fini commerciali il Servizio o una qualunque sua parte nonché l'utilizzo del servizio. L'Utente non potrà altresì in nessun caso trasferire, o cedere una parte o tutte, le proprie obbligazioni nascenti dalle presenti Condizioni a terzi.</li>
                        <li className="mb-2"><span className="pr-2">2.6</span>È vietata la riproduzione della grafica e struttura del Sito, gli elementi costitutivi di questo non possono essere copiati o imitati. Nessun diritto viene riconosciuto all'Utente sul <span className="italic">software</span> relativo al Sito, ivi inclusi gli aggiornamenti, e sui relativi codici sorgente.</li>
                        <li className="mb-2"><span className="pr-2">2.7</span>È fatto divieto espresso all'Utente di porre in essere le attività di cui all'art. 64-bis L. 633/41, quali, a titolo esemplificativo ma non esaustivo: l'estrazione, la riproduzione, la traduzione, l'adattamento, la distribuzione al pubblico in qualunque forma attuata o il trasferimento a terzi del Sito a qualsiasi titolo effettuato, sia esso oneroso che gratuito.</li>
                        <li className="mb-2"><span className="pr-2">2.8</span>Senza preventivo consenso scritto dell'Associazione, è vietato all'Utente effettuare interventi sul Sito, ancorché per la correzione di eventuali vizi e/o difetti, nonché attività di duplicazione, de-compilazione, disassemblaggio, trasformazione, modifica del Sito. È permesso utilizzare i <span className="italic">link</span> diretti alla <span className="italic">homepage</span> e alle pagine interne del presente Sito purché il sito dell'Utente in cui si crei il collegamento non sia correlato al compimento di ogni tipo di reato o qualsiasi altra attività in contrasto con norme di legge. Tutti i diritti non espressamente concessi sono da ritenersi riservati.</li>
                    </ol>
                </section>

                <section className="mt-4">
                    <h3 className="text-lg font-bold">3. Assenza di Garanzia</h3>
                    <ol>
                        <li className="mb-2"><span className="pr-2">3.1</span> L'Associazione non fornisce alcuna garanzia che il Sito o i suoi contenuti siano costantemente accessibili. L'Utente prende inoltre atto che la Piattaforma contiene esclusivamente una raccolta di informazioni relative a talune borse di studio pubblicate dalle principali università italiane raccolte da collaboratori o volontari dell'Associazione e messe a disposizione degli Utenti nella Piattaforma, che, attraverso l'uso di diversi filtri, può aiutare nella consultazione dei vari bandi degli istituti superiori e delle università, i quali sono gli unici enti responsabili per la pubblicazione delle relative borse di studio e per lo svolgimento dei relativi processi di selezione. Alla luce di quanto sopra, accetto che l'Associazione (così come gli associati, i collaboratori e i volontari dell'Associazione) non forniscono alcuna garanzia, né, nei più ampi termini consentiti dalla legge, assumono alcuna responsabilità, qui integralmente rinunciata, circa la completezza del perimetro degli istituti superiori e universitari, né circa la accuratezza, tempestiva pubblicazione o completezza della documentazione o informazioni rese disponibili all'interno della Piattaforma. L'Utente prende atto altresì che la Piattaforma è stata sviluppata per supportare la generalità degli Utenti nella ricerca di borse di studio, e, perciò, sarà il solo soggetto responsabile per la scelta delle borse di studio, per la verifica e la dimostrazione di titolarità dei requisiti eventualmente previsti dalla specifica borsa di studio, per il procedimento di candidatura e selezione e per la verifica degli eventuali aggiornamenti specifici direttamente nei canali ufficiali degli enti emittenti.</li>
                        <li className="mb-2"><span className="pr-2">3.2</span> L'Associazione non sarà inoltre responsabile a fronte di eventuali disservizi consistenti in ritardi o interruzioni nel funzionamento dei servizi di comunicazione elettronica che impediscano l'accesso o l'utilizzo del Sito.</li>
                        <li className="mb-2"><span className="pr-2">3.3</span> L'Associazione non fornisce alcuna garanzia circa l'assenza di <span className="italic">virus, malware</span> o altri elementi che rendono il Sito e/o i suoi contenuti pericolosi. L'Utente è responsabile per l'attuazione di procedure e controlli atti a soddisfare le esigenze di sicurezza e l'affidabilità dei dati in entrata e in uscita, quali ad esempio l&#39;installazione di un applicativo anti-virus.</li>
                        <li className="mb-2"><span className="pr-2">3.4</span> Il Sito contiene inoltre collegamenti ipertestuali (hyperlink) a siti <span className="italic">web</span> gestiti da terzi e l'Associazione non è responsabile dei contenuti di siti terzi resi accessibili tramite il Sito.</li>
                        <li className="mb-2"><span className="pr-2">3.5</span> L'Utente accede e utilizza il Sito autonomamente e a suo esclusivo rischio. L'Associazione declina qualsiasi responsabilità rispetto a qualsivoglia danno o pregiudizio subito in qualsiasi modo dall'Utente in conseguenza dell'accesso e utilizzo del Sito e/o <span className="italic">download</span> di qualsivoglia materiale a qualsiasi titolo presente sul Sito.</li>
                        <li className="mb-2"><span className="pr-2">3.6</span> Quanto precede deve naturalmente intendersi applicabile ove consentito nei limiti massimi di legge.</li>
                    </ol>
                </section>

                <section className="mt-4">
                    <h3 className="text-lg font-bold">4. Indennizzo</h3>
                    <ol>
                        <li className="mb-2">L'Utente accetta di tenere indenne e manlevata l'Associazione (e i relativi associati, collaboratori o volontari) in relazione a qualsiasi responsabilità, danno e costo (incluse, senza alcuna limitazione, spese legali) in cui l'Associazione possa incorrere a causa di: (i) una violazione da parte dell'Utente delle presenti Condizioni; (ii) pretese di terzi che siano fondate sull'uso del Sito e/o del Materiale da parte dell'Utente; ovvero (iii) informazioni o Materiale che sia stato spedito, trasmesso o caricato dall'Utente attraverso il Sito o l'<span className="italic">account</span></li>
                    </ol>
                </section>

                <section className="mt-4">
                    <h3 className="text-lg font-bold">5. Comunicazioni</h3>
                    <ol>
                        <li className="mb-2">Qualsiasi comunicazione dovrà essere inviata al seguente indirizzo e-mail: <a href="mailto:vision.assoitaly@gmail.com">vision.assoitaly@gmail.com</a>. Nel caso in cui si intenda presentare un reclamo o una qualsiasi altra comunicazione relativo al Servizio, è necessario inviare una e-mail agli indirizzi ivi indicati.</li>
                    </ol>
                </section>

                <section className="mt-4">
                    <h3 className="text-lg font-bold">6. Legge applicabile</h3>
                    <ol>
                        <li className="mb-2">Le presenti Condizioni e i rapporti tra l'Associazione e l'tente sono regolati dalla legge italiana.</li>
                    </ol>
                </section>
            </Container>
        </>
    )
}

export default TermsAndConditions;