import React from "react";

const FilterItemUnselected = ({ uKey, onClickFunction, index, name }) => {
    return (
        <div
            key={uKey}
            className="flex items-center text-xs md:text-sm text-gray-900 font-regular font-lato py-3 px-4 bg-[#F5F6FA] hover:bg-cyan-50 gap-1 border-b-[1px]"
            onClick={onClickFunction}
        >
            {/* <span>{index}</span> */}
            <span>{name}</span>
        </div>
    )
}

export default FilterItemUnselected;