const componentStylesheet = {
    containerMain: {
        style: "w-11/12 md:w-7/12 lg:w-5/12 xl:w-4/12 mx-auto"
    },
    inputText: {
        style: "appearance-none rounded-md py-2 px-3 bg-[#F5F6FA] border-[#F4F5F7] border-2 leading-tight focus:outline-none focus:shadow-outline min-w-full"
    },
    buttonPrimaryEnabled: {
        style: "sticky bottom-1 w-full bg-[#6D59EF] text-white py-3 px-4 rounded-md self-start"
    },
    buttonPrimaryDisabled: {
        style: "sticky bottom-1 w-full bg-[#bebad6] text-[#6d688c] py-3 px-4 rounded-md self-start text-sm"
    },
    buttonSecondaryEnabled: {
        style: "sticky bottom-1 w-full text-[#6D59EF] py-2 px-4 rounded-md self-start text-sm font-semibold"
    }
}

export { componentStylesheet };