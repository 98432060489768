import React, { createContext, useState, useEffect } from "react";

export const ContextIndemnity = createContext({});

export const IndemnityProvider = ({ children }) => {

    const [consent, setConsent] = useState({
        'adult': {
            accepted: false
        },
        'terms-and-conditions': {
            accepted: false
        },
        'articles': {
            accepted: false
        }
    });
    const handleConsent = (e) => {
        let obj = consent[e.target.name];
        obj.accepted = !obj.accepted;
        setConsent(() => {
            return ({
                ...consent
            })
        })
    }

    const [acceptedAll, setAcceptedAll] = useState(false);
    useEffect(() => {
        if (consent['adult'].accepted && consent['terms-and-conditions'].accepted && consent['articles'].accepted) {
            setAcceptedAll(true);
        } else {
            setAcceptedAll(false);
        }
    }, [consent])

    const [hasAcceptedIndemnity, setHasAcceptedIndemnity] = useState(false);
    const handleIndemnity = () => {
        // localStorage.setItem('hasAcceptedIndemnity', true)
        setHasAcceptedIndemnity(true);
    }
    // useEffect(() => {
    //     setHasAcceptedIndemnity(JSON.parse(localStorage.getItem('hasAcceptedIndemnity')));
    // }, [hasAcceptedIndemnity])

    return (
        <ContextIndemnity.Provider value={{ consent, acceptedAll, handleConsent, handleIndemnity, hasAcceptedIndemnity }}>{children}</ContextIndemnity.Provider>
    )
}