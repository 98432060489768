export const italianRegions = [
    {
        identifier: 1,
        name: "Abruzzo"
    },{
        identifier: 2,
        name: "Basilicata"
    },{
        identifier: 3,
        name: "Calabria"
    },{
        identifier: 4,
        name: "Campania"
    },{
        identifier: 5,
        name: "Emilia-Romagna"
    },{
        identifier: 6,
        name: "Friuli-Venezia Giulia"
    },{
        identifier: 7,
        name: "Lazio"
    },{
        identifier: 8,
        name: "Liguria"
    },{
        identifier: 9,
        name: "Lombardia"
    },{
        identifier: 10,
        name: "Marche"
    },{
        identifier: 11,
        name: "Molise"
    },{
        identifier: 12,
        name: "Piemonte"
    },{
        identifier: 13,
        name: "Puglia"
    },{
        identifier: 14,
        name: "Sardegna"
    },{
        identifier: 15,
        name: "Sicilia"
    },{
        identifier: 16,
        name: "Toscana"
    },{
        identifier: 17,
        name: "Trentino-Alto Adige/Südtirol"
    },{
        identifier: 18,
        name: "Umbria"
    },{
        identifier: 19,
        name: "Valle d'Aosta/Vallée d'Aoste"
    },{
        identifier: 20,
        name: "Veneto"
    }
]