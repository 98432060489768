export const degreeList = [
    {
        identifier: 1,
        name: "Triennale"
    },{
        identifier: 2,
        name: "Magistrale"
    },{
        identifier: 3,
        name: "Magistrale a Ciclo Unico"
    }
]