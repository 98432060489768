import React from "react";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const Container = ({children, more}) => {
    return (
        <div className={`${componentStylesheet.containerMain.style} ${more}`}>
            {children}
        </div>
    )
}

export default Container;