import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../custom-hooks/outsideClick";

import FilterItemUnselected from "./FilterItemUnselected";
import FilterItemSelected from "./FilterItemSelected";
import FilterInput from "./FilterInput";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const FacultyFilter = ({ faculties, selectedFaculties, setSelectedFacultiesAndUpdateFilters, getScholarships}) => {

    // autocomplete to instantly filter the faculty list based on what the user has typed in the input field
    const [search, setSearch] = useState("");
    const [searchedFaculties, setSearchedFaculties] = useState([]);
    const searchFaculties = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        if ( e.target.value.length > 0 ) {
            setSearchedFaculties(faculties.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())))            
        } else {
            setSearchedFaculties([]);
        }
    }

    const addSelectedFaculty = (item) => {
        // adds faculty to the "selected faculties" state
        const newSelectedFaculties = [...selectedFaculties, item];
        
        // removes the selected faculty from the "searched faculties" state
        if ( searchedFaculties.length > 0 ) {
            setSearchedFaculties(searchedFaculties.filter((el) => el.identifier.toLowerCase() !== item.identifier.toLowerCase()))
        }

        // empty the value of the input field
        setSearch("");

        setIsFocus(false);

        setSelectedFacultiesAndUpdateFilters(newSelectedFaculties);
    }

    const removeSelectedFaculty = (item) => {
        // removes the faculty from the "selected faculties" state
         const newSelectedFaculties = selectedFaculties.filter((el) => el.identifier.toLowerCase() !== item.identifier.toLowerCase());

        // re-adds the unselected faculty to the searched faculty list only if the user is searching something in the input and if the name of the canceled faculty includes the current value in the input
        if ( searchedFaculties.length > 0 && item.name.toLowerCase().includes(search.toLowerCase()) ) {
            setSearchedFaculties([...searchedFaculties, item])
        }

        setSelectedFacultiesAndUpdateFilters(newSelectedFaculties);    
    }

    // detect if the input field is in focus and changes state accordingly to expand/reduce the dropdown
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => {
        setIsFocus(true)
    }
    
    const handleClickOutside = () => {
        setIsFocus(false)
    };
    const ref = useOutsideClick(handleClickOutside);

    const isMounted = useRef(false);
    useEffect(() => {
        if ( isMounted.current ) {
            getScholarships();            
        } else {
            isMounted.current = true;
        }        
    }, [selectedFaculties]);

    return (
        <div id="faculties" className="flex flex-col w-auto" ref={ref}>
            <FilterInput
                label="Facoltà"
                selectedValues={selectedFaculties}
                type="text"
                placeholder="Cerca facoltà"
                style={componentStylesheet.inputText.style}
                onChangeFunction={searchFaculties}
                onFocusFunction={handleFocus}
                search={search}
            />

            <div className="flex flex-wrap gap-1 mt-1">
                {selectedFaculties?.map((filter, index) => {
                    return(
                            <FilterItemSelected
                                key={filter.identifier}
                                index={index + 1}
                                name={filter.name}
                                onClickFunction={() => removeSelectedFaculty(filter)}
                            />
                        )
                    })
                }
            </div>

            <div className={`max-h-60 mt-1 drop-shadow-2xl rounded-xl overflow-y-auto ${isFocus ? "block" : "hidden"}`}>
                {searchedFaculties.length > 0 ?
                    searchedFaculties.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedFaculty(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                :
                    faculties?.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedFaculty(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FacultyFilter;   