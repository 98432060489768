import React, { useEffect, useState } from "react";

import Container from "./Container";
import Illustration429 from "../assets/illustrations/429-illustration.svg"
import { componentStylesheet } from "../component-styles/componentStylesheet";

const Error429 = ({ tooManyRequests, setTooManyRequests }) => {

    const [seconds, setSeconds] = useState(60);
    useEffect(() => {
        setTimeout(() => {
            if ( seconds > 0 ) {
                setSeconds(prev => prev - 1)
            } else if ( seconds === 0 ) {
                setTooManyRequests(false);
                window.location.reload(true);
            }
        }, 1000)
    }, [seconds])

    return (
        <div className={`h-full w-full absolute flex flex-col items-center justify-center z-[10000] bg-[#F5F6FA] ${tooManyRequests ? "bottom-[0]" : "bottom-[-101%]"}`}>
            <Container more={"flex flex-col items-center justify-between"}>
                <img src={Illustration429} alt="" />
                <p className="text-center font-semibold text-sm w-4/5 my-2">Troppe richieste! Ricorda, ogni volta che selezioni o deselezioni un filtro, la ricerca si aggiorna automaticamente</p>

                {seconds > 0 &&
                    <p className="text-center text-sm w-4/5 mb-2">Potrai tornare a cercare tra {seconds} secondi</p>
                }

                {seconds === 0 &&
                    <div className="w-4/5 mt-4">
                        <p className="text-center text-xs mb-2">Se non sei stato reindirizzato automaticamente, clicca qui:</p>
                        <button
                            className={componentStylesheet.buttonSecondaryEnabled.style}
                            onClick={() => {
                                setTooManyRequests(false);
                                window.location.reload(true);
                            }}
                        >
                            Torna alla ricerca
                        </button>
                    </div>
                }                
            </Container>
        </div>
    )
}

export default Error429;