import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../custom-hooks/outsideClick";
import { degreeList } from "../data/degrees";

import FilterItemUnselected from "./FilterItemUnselected";
import FilterItemSelected from "./FilterItemSelected";
import FilterInput from "./FilterInput";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const DegreeFilter = ({ selectedDegrees, setSelectedDegrees, getScholarships }) => {
    
    // set degrees from local js array
    const [degrees, setDegrees] = useState(degreeList);

    // autocomplete to instantly filter the degree list based on what the user has typed in the input field
    const [search, setSearch] = useState("");
    const [searchedDegrees, setSearchedDegrees] = useState([]);
    const searchDegrees = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        if ( e.target.value.length > 0 ) {
            setSearchedDegrees(degrees.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())))            
        } else {
            setSearchedDegrees([]);
        }
    }

    const addSelectedDegree = (item) => {
        // adds degree to the "selected degrees" state
        setSelectedDegrees([
            ...selectedDegrees,
            {
                ...item
            }
        ]);

        // removes the selected degree from the "degrees" state
        setDegrees(degrees.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        
        // removes the selected degree from the "searched degrees" state
        if ( searchedDegrees.length > 0 ) {
            setSearchedDegrees(searchedDegrees.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        }

        // empty the value of the input field
        setSearch("");

        setIsFocus(false);
    }

    const removeSelectedDegree = (item) => {
        // removes the degree from the "selected degrees" state
        setSelectedDegrees(selectedDegrees.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()));

        // re-adds the unselected degree to the degree list
        setDegrees([
            ...degrees,
            {
                ...item
            }
        ])

        // re-adds the unselected degree to the searched degree list only if the user is searching something in the input and if the name of the canceled degree includes the current value in the input
        if ( searchedDegrees.length > 0 ) {
            if ( item.name.toLowerCase().includes(search.toLowerCase()) )
            setSearchedDegrees([
                ...searchedDegrees,
                {
                    ...item
                }
            ])
        }
    }

    // detect if the input field is in focus and changes state accordingly to expand/reduce the dropdown
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => {
        setIsFocus(true)
    }
    
    const handleClickOutside = () => {
        setIsFocus(false)
    };
    const ref = useOutsideClick(handleClickOutside);

    const isMounted = useRef(false);
    useEffect(() => {
        if ( isMounted.current ) {
            getScholarships();            
        } else {
            isMounted.current = true;
        }        
    }, [selectedDegrees]);

    return (
        <div id="degrees" className="flex flex-col w-auto" ref={ref}>
            <FilterInput
                label="Grado"
                selectedValues={selectedDegrees}
                type="text"
                placeholder="Seleziona grado universitario"
                style={componentStylesheet.inputText.style}
                onChangeFunction={searchDegrees}
                onFocusFunction={handleFocus}
                search={search}
            />

            <div className="flex flex-wrap gap-1 mt-1">
                {selectedDegrees?.map((filter, index) => {
                    return(
                            <FilterItemSelected
                                key={filter.identifier}
                                index={index + 1}
                                name={filter.name}
                                onClickFunction={() => removeSelectedDegree(filter)}
                            />
                        )
                    })
                }
            </div>

            <div className={`max-h-60 mt-1 drop-shadow-2xl rounded-xl overflow-y-auto ${isFocus ? "block" : "hidden"}`}>
                {searchedDegrees.length > 0 ?
                    searchedDegrees.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedDegree(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                :
                    degrees?.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedDegree(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DegreeFilter;