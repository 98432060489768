import React from "react";

const FilterInput = ({ style, onChangeFunction, onFocusFunction, search, placeholder, type, label, selectedValues }) => {
    return (
        <div>
            <label className="text-sm font-semibold">
                {label}
                <span className="font-semibold text-xs">
                    {selectedValues.length > 0 ? ` (${selectedValues.length} selezionate)` : ""}
                </span>
            </label>

            <input
                type={type}
                placeholder={placeholder}
                className={style}
                onChange={onChangeFunction}
                onFocus={onFocusFunction}
                value={search}
            />
        </div>        
    )
}

export default FilterInput;