import React from "react";
import { useNavigate } from "react-router-dom";
import { Widget } from '@typeform/embed-react'

import Container from "../components/Container";

import IconArrowLeftWhite from "../assets/icons/icon-arrow-left-white.svg";

const TypeformFeedback = () => {

    const navigate = useNavigate();

    return (
        <>
            <nav className={`z-30 flex items-center sticky top-0 w-full h-[8vh] bg-[#FFFFFF]`}>
                <Container more={"flex justify-start"}>
                <div className={`relative w-8 h-8 bg-[#6D59EF] rounded-full flex items-center justify-center overflow-clip`}>
                        <div className={`absolute flex flex-col w-8 `}>
                            <img src={IconArrowLeftWhite} alt="close" onClick={() => navigate(-1)} />
                        </div>
                    </div>
                </Container>
            </nav>
            <Widget id="uDfZPAe3" style={{ width: '100%', height:'92vh' }} className="my-form" />            
        </>
    )
}

export default TypeformFeedback;