import React from "react";

import IconClosePurple from "../assets/icons/icon-close-purple.svg";

const FilterItemSelected = ({ uKey, index, name, onClickFunction }) => {
    return (
        <div
            key={uKey}
            className="flex items-center justify-start align-self py-1 px-1 rounded-md bg-[#EBE8FB] inline-block gap-1"
        >
            <p className="text-xs">#{name.charAt(0).toUpperCase() + name.slice(1)}</p>
            <img className="w-4 h-4" onClick={onClickFunction} src={IconClosePurple} />            
        </div>
    )
}

export default FilterItemSelected;