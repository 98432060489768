import React from "react";

import IconDocPurple from "../assets/icons/icon-doc-purple.svg";

import IconMeritGold from "../assets/icons/icon-merit-gold.svg"
import IconRefugeeBlue from "../assets/icons/icon-refugee-blue.svg"
import IconMeritGreen from "../assets/icons/icon-merit-green.svg"
import IconInvalidityRed from "../assets/icons/icon-invalidity-red.svg"
import IconIncomePetrol from "../assets/icons/icon-income-petrol.svg"
import IconSportPurple from "../assets/icons/icon-sport-purple.svg"
import IconThesisBlue from "../assets/icons/icon-thesis-blue.svg"
import IconOthersGrey from "../assets/icons/icon-other-grey.svg"

const ScholarshipCard = ({ uKey, name, scholarship_type, pdf_link, website_link, uniName, uniRegions, undergraduate, graduate, combined, index, facultyNames }) => {
    return (
        <div key={uKey} className="bg-white mt-2 rounded-lg py-4 px-4 border-[#F4F5F7] border-2">
            <div className="flex gap-4 mb-2 justify-between">                
                <div>
                    {
                        uniRegions.map((region) => {
                            return (
                                <p className="text-xs py-1 px-2 rounded-full bg-[#F5F6FA] mr-1 mb-1 inline-block" key={region}>{region + " "}</p>
                            )
                        })
                    }
                </div>
                <span className="w-2 h-2 px-3 py-3 rounded-full bg-[#F5F6FA] flex items-center justify-center self-start">{index + 1}</span>
            </div>

            <h3 className="text-lg md:text-xl font-bold mt-2">{name}</h3>
            <p className="font-regular text-sm text-gray-700 font-lato">{uniName.charAt(0).toUpperCase() + uniName.slice(1)}</p>

            
            {/* 
            this is the function to render the faculties of each scholarship, uncomment in case it's requested
            <div className="mt-2">
                {
                    facultyNames?.map(fac => {
                        return(
                            <p className="text-xs py-1 px-2 rounded-full bg-[#F5F6FA] mr-1 mb-1 inline-block" key={fac}>{fac}</p>
                        )
                    })
                }
            </div> 
            */}

            <div className={`flex flex-col my-4 ${undergraduate ? "gap-4" : graduate ? "gap-4" : ""}`}>
                <div className="self-start">
                    <div
                        className={`flex items-center px-1 py-1 rounded-md  ${
                            scholarship_type === 'Merito' ? "bg-[#EEFFFA]"
                            : scholarship_type === 'Reddito' ? "bg-[#F3FEFF]"
                            : scholarship_type === 'Merito & Reddito' ? "bg-[#FFFAEC]"
                            : scholarship_type === 'Invalidità' ? "bg-[#FFF2F8]"
                            : scholarship_type === 'Rifugiati & Cittadini Stranieri' ? "bg-[#F4F8FF]"
                            : scholarship_type === 'Sport' ? "bg-[#FBF3FF]"
                            : scholarship_type === 'Tesi' ? "bg-[#EAF6FF]"
                            : scholarship_type === 'Altro' ? "bg-[#F1F1F1]"
                            : ""
                        }`}
                    >
                        <img className="w-5" alt=""
                            src={
                                scholarship_type === 'Merito' ? IconMeritGreen
                                : scholarship_type === 'Reddito' ? IconIncomePetrol
                                : scholarship_type === 'Merito & Reddito' ? IconMeritGold
                                : scholarship_type === 'Invalidità' ? IconInvalidityRed
                                : scholarship_type === 'Rifugiati & Cittadini Stranieri' ? IconRefugeeBlue
                                : scholarship_type === 'Sport' ? IconSportPurple
                                : scholarship_type === 'Tesi' ? IconThesisBlue
                                : scholarship_type === 'Altro' ? IconOthersGrey
                                : ""
                            }
                        />
                        <span
                            className={`text-sm font-semibold grow-0 leading-none ${
                                scholarship_type === 'Merito' ? "text-[#00B981]"
                                : scholarship_type === 'Reddito' ? "text-[#007880]"
                                : scholarship_type === 'Merito & Reddito' ? "text-[#FFB800]"
                                : scholarship_type === 'Invalidità' ? "text-[#9F0850]"
                                : scholarship_type === 'Rifugiati & Cittadini Stranieri' ? "text-[#2B519B]"
                                : scholarship_type === 'Sport' ? "text-[#B857E5]"
                                : scholarship_type === 'Tesi' ? "text-[#008AEE]"
                                : scholarship_type === 'Altro' ? "text-[#313131]"
                                : ""
                            }`}
                        >
                            {scholarship_type}
                        </span>
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    {undergraduate === true &&
                        <span className="text-sm font-semibold">Triennale</span>
                    }
                    {graduate === true &&
                        <span className="text-sm font-semibold">Magistrale</span>
                    }
                    {combined === true &&
                        <span className="text-sm font-semibold">Magistrale a Ciclo Unico</span>
                    }
                </div>
            </div>

            

            <div className="flex gap-2 mt-2 border-t-[1px] border-[#EBE8FB] pt-4">
                {pdf_link &&
                    <a className="flex items-center py-1 px-2 bg-[#F4F3FD] rounded-md" href={pdf_link} target="blank">
                        <img className="w-5" src={IconDocPurple} alt="" />
                        <span className="text-[#6D59EF] text-sm font-semibold">PDF</span>
                    </a>
                }
                {website_link &&
                    <a className="text-[#6D59EF] text-sm font-semibold py-1 px-2 bg-[#F4F3FD] rounded-md" href={website_link} target="blank">Visita il sito web</a>
                }
            </div>
            
        </div>
    )
}

export default ScholarshipCard;