import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../custom-hooks/outsideClick";

import FilterItemUnselected from "./FilterItemUnselected";
import FilterItemSelected from "./FilterItemSelected";
import FilterInput from "./FilterInput";

import { componentStylesheet } from "../component-styles/componentStylesheet";

const RegionFilter = ({regions, selectedRegions, setSelectedRegionsAndUpdateFilters, getScholarships}) => {

    // autocomplete to instantly filter the region list based on what the user has typed in the input field
    const [search, setSearch] = useState("");
    const [searchedRegions, setSearchedRegions] = useState([]);
    const searchRegions = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        if ( e.target.value.length > 0 ) {
            setSearchedRegions(regions.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())))            
        } else {
            setSearchedRegions([]);
        }
    }

    const addSelectedRegion = (item) => {
        // adds region to the "selected regions" state
        const newSelectedRegions = [...selectedRegions, item];

        // removes the selected region from the "regions" state
//        setRegions(regions.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        
        // removes the selected region from the "searched regions" state
        if ( searchedRegions.length > 0 ) {
            setSearchedRegions(searchedRegions.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase()))
        }

        // empty the value of the input field
        setSearch("");

        setIsFocus(false);

        setSelectedRegionsAndUpdateFilters(newSelectedRegions);
    }

    const removeSelectedRegion = (item) => {
        // removes the region from the "selected regions" state
        const newSelectedRegions = selectedRegions.filter((el) => el.name.toLowerCase() !== item.name.toLowerCase());

        // re-adds the unselected region to the searched region list only if the user is searching something in the input and if the name of the canceled region includes the current value in the input
        if ( searchedRegions.length > 0 && item.name.toLowerCase().includes(search.toLowerCase()) ) {
            setSearchedRegions([...searchedRegions, item]);
        }

        setSelectedRegionsAndUpdateFilters(newSelectedRegions);
    }

    // detect if the input field is in focus and changes state accordingly to expand/reduce the dropdown
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => {
        setIsFocus(true)
    }
    
    const handleClickOutside = () => {
        setIsFocus(false)
    };
    const ref = useOutsideClick(handleClickOutside);

    const isMounted = useRef(false);
    useEffect(() => {
        if ( isMounted.current ) {
            getScholarships();            
        } else {
            isMounted.current = true;
        }        
    }, [selectedRegions]);

    return (
        <div id="regions" className="flex flex-col w-auto" ref={ref}>
            <FilterInput
                label="Regione"
                selectedValues={selectedRegions}
                type="text"
                placeholder="Cerca regione"
                style={componentStylesheet.inputText.style}
                onChangeFunction={searchRegions}
                onFocusFunction={handleFocus}
                search={search}
            />

            <div className="flex flex-wrap gap-1 mt-1">
                {selectedRegions?.map((filter, index) => {
                    return(
                            <FilterItemSelected
                                key={filter.identifier}
                                index={index + 1}
                                name={filter.name}
                                onClickFunction={() => removeSelectedRegion(filter)}
                            />
                        )
                    })
                }
            </div>

            <div className={`max-h-60 mt-1 drop-shadow-2xl rounded-xl overflow-y-auto ${isFocus ? "block" : "hidden"}`}>
                {searchedRegions.length > 0 ?
                    searchedRegions.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedRegion(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                :
                    regions?.map((item, index) => {
                        return (
                            <FilterItemUnselected
                                key={item.identifier}
                                onClickFunction={() => {addSelectedRegion(item)}}
                                index={index + 1}
                                name={item.name}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RegionFilter;