import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';

import Search from './routes/Search';
import TypeformNewsletter from './routes/TypeformNewsletter';
import TypeformFeedback from './routes/TypeformFeedback';
import TermsAndConditions from './routes/TermsAndConditions';

function App() {

  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState([]);
  
  const [results, setResults] = useState([]);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Search
      selectedUniversities={selectedUniversities}
      setSelectedUniversities={setSelectedUniversities}

      selectedFaculties={selectedFaculties}
      setSelectedFaculties={setSelectedFaculties}

      selectedRegions={selectedRegions}
      setSelectedRegions={setSelectedRegions}

      selectedTypes={selectedTypes}
      setSelectedTypes={setSelectedTypes}

      selectedDegrees={selectedDegrees}
      setSelectedDegrees={setSelectedDegrees}

      results={results}        
      setResults={setResults}
    />
    },{
      path: '/subscribe-to-newsletter',
      element: <TypeformNewsletter />
    },{
      path: '/give-us-feedback',
      element: <TypeformFeedback />
    },{
      path: '/terms-and-conditions',
      element: <TermsAndConditions />
    }    
  ])

  return (   
    <RouterProvider router={router} />
  );
}

export default App;
